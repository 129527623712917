/* Load Bourbon Base Styles */
button, input[type="button"], input[type="reset"], input[type="submit"],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  background-color: #477dca;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus,
  button:hover,
  button:focus {
    background-color: #2c5999;
    color: #fff; }
  button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled,
  button:disabled {
    cursor: not-allowed;
    opacity: 0.5; }

fieldset {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin: 0 0 0.75em;
  padding: 1.5em; }

input,
label,
select {
  display: block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em; }

label {
  font-weight: 600;
  margin-bottom: 0.375em; }
  label.required::after {
    content: "*"; }
  label abbr {
    display: none; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea,
select[multiple=multiple],
textarea {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, textarea:hover,
  select[multiple=multiple]:hover,
  textarea:hover {
    border-color: #c4c4c4; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, textarea:focus,
  select[multiple=multiple]:focus,
  textarea:focus {
    border-color: #477dca;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(55, 112, 192, 0.7);
    outline: none; }

textarea {
  resize: vertical; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

input[type="file"] {
  padding-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 1.5em;
  max-width: 100%;
  width: auto; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }
dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: bold;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

table {
  -webkit-font-feature-settings: "kern", "liga", "tnum";
  -moz-font-feature-settings: "kern", "liga", "tnum";
  -ms-font-feature-settings: "kern", "liga", "tnum";
  font-feature-settings: "kern", "liga", "tnum";
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #b7b7b7;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  -webkit-font-feature-settings: "kern", "liga", "pnum";
  -moz-font-feature-settings: "kern", "liga", "pnum";
  -ms-font-feature-settings: "kern", "liga", "pnum";
  font-feature-settings: "kern", "liga", "pnum";
  -webkit-font-smoothing: antialiased;
  color: #333;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  line-height: 1.2;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #477dca;
  text-decoration: none;
  transition: color 0.1s linear; }
  a:active, a:focus, a:hover {
    color: #2c5999; }
  a:active, a:focus {
    outline: none; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

/* Load custom Mixins */
/* Load Variables and Layout Information */
header {
  background: #eee;
  border-bottom: 2px solid #bbb;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px #ccc;
  overflow: hidden; }
  header .logo {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    padding: 20px 0 10px;
    border: none;
    outline: none; }
    header .logo:last-child {
      margin-right: 0; }
    header .logo img {
      outline: none;
      border: 0; }
  header nav {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
    padding-top: 34px; }
    header nav:last-child {
      margin-right: 0; }
    header nav ul {
      float: right; }
    header nav li {
      float: left;
      margin-left: 20px; }
    header nav a {
      display: block;
      color: #00457C;
      border-radius: 4px;
      font-size: 16px;
      line-height: 18px;
      padding: 10px 20px;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      transition: all 0.4s; }
    header nav a:hover {
      background: #00457C;
      color: #fff; }

.login {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%;
  margin-left: 25.58941%; }
  .login:last-child {
    margin-right: 0; }

.login-errors {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%;
  margin-left: 25.58941%; }
  .login-errors:last-child {
    margin-right: 0; }

.error-message {
  font-size: 1.1em;
  border-radius: 6px;
  background: #F76A58;
  border: 1px solid #f53f27;
  padding: 10px 30px;
  box-shadow: 0 4px 0 #f53f27;
  margin-bottom: 24px;
  color: #fff;
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .error-message::after {
    clear: both;
    content: "";
    display: table; }
  .error-message .fa {
    font-size: 4em;
    width: 88px;
    float: left;
    padding-top: 6px;
    padding-bottom: 6px; }
  .error-message .message {
    margin-left: 88px; }
  .error-message strong {
    font-size: 1.4em;
    font-family: "Roboto", Helvetica, Arial, sans-serif; }
  .error-message p strong {
    font-size: 1em;
    font-family: "Roboto", Helvetica, Arial, sans-serif; }

.success-message {
  font-size: 1.1em;
  border-radius: 6px;
  background: #00457C;
  border: 1px solid #002949;
  padding: 10px 30px;
  box-shadow: 0 4px 0 #002949;
  margin-bottom: 24px;
  color: #fff;
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .success-message::after {
    clear: both;
    content: "";
    display: table; }
  .success-message .fa {
    font-size: 4em;
    width: 88px;
    float: left;
    padding-top: 6px;
    padding-bottom: 6px; }
  .success-message .message {
    margin-left: 88px; }
  .success-message strong {
    font-size: 1.4em;
    font-family: "Roboto", Helvetica, Arial, sans-serif; }
  .success-message p strong {
    font-size: 1em;
    font-family: "Roboto", Helvetica, Arial, sans-serif; }

.notice-message {
  font-size: 1.1em;
  border-radius: 6px;
  background: #7C8587;
  border: 1px solid #636b6d;
  padding: 10px 30px;
  box-shadow: 0 4px 0 #636b6d;
  margin-bottom: 24px;
  color: #fff;
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .notice-message::after {
    clear: both;
    content: "";
    display: table; }
  .notice-message .fa {
    font-size: 4em;
    width: 88px;
    float: left;
    padding-top: 6px;
    padding-bottom: 6px; }
  .notice-message .message {
    margin-left: 88px; }
  .notice-message strong {
    font-size: 1.4em;
    font-family: "Roboto", Helvetica, Arial, sans-serif; }
  .notice-message p strong {
    font-size: 1em;
    font-family: "Roboto", Helvetica, Arial, sans-serif; }

.confirm-message {
  font-size: 1.1em;
  border-radius: 6px;
  background: #efefef;
  border: 1px solid #d5d5d5;
  padding: 10px 30px;
  box-shadow: 0 4px 0 #d5d5d5;
  margin-bottom: 24px;
  color: #fff;
  max-width: 68em;
  margin-left: auto;
  margin-right: auto;
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 65.88078%;
  max-width: 68em;
  margin-left: auto;
  margin-right: auto;
  margin-left: 17.05961%;
  color: #333; }
  .confirm-message::after {
    clear: both;
    content: "";
    display: table; }
  .confirm-message .fa {
    font-size: 4em;
    width: 88px;
    float: left;
    padding-top: 6px;
    padding-bottom: 6px; }
  .confirm-message .message {
    margin-left: 88px; }
  .confirm-message strong {
    font-size: 1.4em;
    font-family: "Roboto", Helvetica, Arial, sans-serif; }
  .confirm-message p strong {
    font-size: 1em;
    font-family: "Roboto", Helvetica, Arial, sans-serif; }
  .confirm-message:last-child {
    margin-right: 0; }
  .confirm-message::after {
    clear: both;
    content: "";
    display: table; }
  .confirm-message .fa {
    color: #F6C05C;
    text-shadow: 0 2px 0 #f3ad2c; }
  .confirm-message a.confirm {
    background: #00457C;
    color: #fff;
    padding: .6em 1.2em;
    text-align: center;
    border-radius: 6px;
    position: relative;
    font-weight: bold;
    top: 0;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    text-shadow: 1px 1px 0 #002949;
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 0 2px 0 #000c16;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    clear: left; }
    .confirm-message a.confirm:hover, .confirm-message a.confirm:focus {
      box-shadow: 0 4px 0 #000c16;
      top: -2px;
      background: #005090;
      outline: none;
      color: #fff; }
    .confirm-message a.confirm:active {
      background: #003a68;
      top: 2px;
      box-shadow: 0 0 0 #000c16; }
    .confirm-message a.confirm:last-child {
      margin-right: 0; }
  .confirm-message a.deny {
    background: #F76A58;
    color: #fff;
    padding: .6em 1.2em;
    text-align: center;
    border-radius: 6px;
    position: relative;
    font-weight: bold;
    top: 0;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    text-shadow: 1px 1px 0 #f53f27;
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 0 2px 0 #de230b;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
    .confirm-message a.deny:hover, .confirm-message a.deny:focus {
      box-shadow: 0 4px 0 #de230b;
      top: -2px;
      background: #f87b6b;
      outline: none;
      color: #fff; }
    .confirm-message a.deny:active {
      background: #f65945;
      top: 2px;
      box-shadow: 0 0 0 #de230b; }
    .confirm-message a.deny:last-child {
      margin-right: 0; }

body {
  padding: 0;
  margin: 0; }

.container {
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .container::after {
    clear: both;
    content: "";
    display: table; }

fieldset {
  border-radius: 6px;
  box-shadow: 0 3px 0 #ccc; }

button[type=submit] {
  background: #01A3E4;
  color: #fff;
  padding: .6em 1.2em;
  text-align: center;
  border-radius: 6px;
  position: relative;
  font-weight: bold;
  top: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  text-shadow: 1px 1px 0 #017fb1;
  margin-top: 12px;
  margin-bottom: 12px;
  box-shadow: 0 2px 0 #015a7e;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
  margin-top: 20px;
  min-width: 150px; }
  button[type=submit]:hover, button[type=submit]:focus {
    box-shadow: 0 4px 0 #015a7e;
    top: -2px;
    background: #01b2f8;
    outline: none;
    color: #fff; }
  button[type=submit]:active {
    background: #0194d0;
    top: 2px;
    box-shadow: 0 0 0 #015a7e; }

table thead tr {
  background: #7C8587;
  color: #fff;
  border-bottom: 2px solid #636b6d; }
table thead th {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  text-shadow: 1px 1px 0 #636b6d;
  font-size: 1.1em;
  padding: 10px 20px; }
table thead th:first-child {
  border-radius: 6px 0 0 0; }
table thead th:last-child {
  border-radius: 0 6px 0 0; }
table tbody tr:nth-child(odd) {
  background: #f9fafa; }
table tbody tr:nth-child(even) {
  background: #fcfcfc; }
table tbody tr td {
  padding: 10px 20px;
  border-left: 1px solid #e6e8e9; }
table tbody tr td:first-child {
  border-left: none; }
table tbody tr:hover {
  background: #e9ebeb; }
  table tbody tr:hover td {
    border-left: 1px solid #cccfd0; }
    table tbody tr:hover td:first-child {
      border-left: none; }
table tbody tr:last-child td {
  border-bottom: none; }
table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 6px; }
table tbody tr:last-child td:last-child {
  border-radius: 0 0 6px 0; }
table .center {
  text-align: center; }
table .action a {
  display: inline-block;
  font-size: 1.2em;
  padding-right: 10px;
  color: #333; }
  table .action a:last-child {
    padding-right: 0; }

.no-files {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 65.88078%;
  margin-left: 17.05961%; }
  .no-files:last-child {
    margin-right: 0; }

.export {
  background: #00457C;
  color: #fff;
  padding: .6em 1.2em;
  text-align: center;
  border-radius: 6px;
  position: relative;
  font-weight: bold;
  top: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  text-shadow: 1px 1px 0 #002949;
  margin-top: 12px;
  margin-bottom: 12px;
  box-shadow: 0 2px 0 #000c16;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 23.23176%;
  margin-left: 76.76824%; }
  .export:hover, .export:focus {
    box-shadow: 0 4px 0 #000c16;
    top: -2px;
    background: #005090;
    outline: none;
    color: #fff; }
  .export:active {
    background: #003a68;
    top: 2px;
    box-shadow: 0 0 0 #000c16; }
  .export:last-child {
    margin-right: 0; }

.admin-actions {
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .admin-actions::after {
    clear: both;
    content: "";
    display: table; }
  .admin-actions a {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    background: #01A3E4;
    color: #fff;
    padding: .6em 1.2em;
    text-align: center;
    border-radius: 6px;
    position: relative;
    font-weight: bold;
    top: 0;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    text-shadow: 1px 1px 0 #017fb1;
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 0 2px 0 #015a7e;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s; }
    .admin-actions a:last-child {
      margin-right: 0; }
    .admin-actions a:hover, .admin-actions a:focus {
      box-shadow: 0 4px 0 #015a7e;
      top: -2px;
      background: #01b2f8;
      outline: none;
      color: #fff; }
    .admin-actions a:active {
      background: #0194d0;
      top: 2px;
      box-shadow: 0 0 0 #015a7e; }

.upload-form {
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .upload-form::after {
    clear: both;
    content: "";
    display: table; }
  .upload-form .fa {
    font-size: 1em;
    padding: 0;
    width: auto;
    float: none; }
  .upload-form .upload-selected {
    float: left;
    display: table-cell;
    width: 66.66667%;
    border-radius: 6px 0 0 6px;
    padding: 0.6em 1.2em 0.55em;
    border: none;
    background: #eee;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    box-shadow: 0 2px 0 #ccc;
    line-height: 1.5em; }
  .upload-form .file-upload {
    background: #01A3E4;
    color: #fff;
    padding: .6em 1.2em;
    text-align: center;
    border-radius: 6px;
    position: relative;
    font-weight: bold;
    top: 0;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    text-shadow: 1px 1px 0 #017fb1;
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 0 2px 0 #015a7e;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
    display: table-cell;
    width: 33.33333%;
    border-radius: 0 6px 6px 0;
    position: relative;
    overflow: hidden;
    margin: 10px; }
    .upload-form .file-upload:hover, .upload-form .file-upload:focus {
      box-shadow: 0 4px 0 #015a7e;
      top: -2px;
      background: #01b2f8;
      outline: none;
      color: #fff; }
    .upload-form .file-upload:active {
      background: #0194d0;
      top: 2px;
      box-shadow: 0 0 0 #015a7e; }
  .upload-form .file-upload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-size: 200px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0); }
  .upload-form button[type=submit] {
    background: #00457C;
    color: #fff;
    padding: .6em 1.2em;
    text-align: center;
    border-radius: 6px;
    position: relative;
    font-weight: bold;
    top: 0;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    text-shadow: 1px 1px 0 #002949;
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 0 2px 0 #000c16;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
    display: table-cell;
    width: 33.33333%;
    float: right; }
    .upload-form button[type=submit]:hover, .upload-form button[type=submit]:focus {
      box-shadow: 0 4px 0 #000c16;
      top: -2px;
      background: #005090;
      outline: none;
      color: #fff; }
    .upload-form button[type=submit]:active {
      background: #003a68;
      top: 2px;
      box-shadow: 0 0 0 #000c16; }

#overlay {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2; }

.modal {
  position: fixed;
  z-index: 3;
  top: 10%;
  left: 0;
  opacity: 1;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s; }

.hide {
  top: -50%;
  opacity: 0; }

body {
  font-family: "Roboto", Helvetica, Arial, sans-serif; }

h1, h2, h3, h4, h5 {
  font-family: "Roboto", Helvetica, Arial, sans-serif; }

h1 {
  font-size: 1.6em;
  color: #00457C;
  padding: 0 20px; }

@media print {
  body {
    font-size: 50%; }

  .admin-actions {
    display: none !important; }

  header nav a {
    display: none !important; }

  tr td:last-child, tr th:last-child {
    display: none !important; }

  a.export {
    display: none !important; } }
